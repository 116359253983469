import React, { useEffect } from 'react'
import { useLocation, useOutletContext } from 'react-router-dom'
import { Grid } from '@mui/material'
import { PreviewBasicInfo } from './(basic-info)/preview-basic-info'
import { EditBasicInfo } from './(basic-info)/edit-basic-info'
import { useForm, FormProvider, SubmitHandler, FieldValues } from 'react-hook-form'
import {
  basicInfoSchema,
  BasicInfoValues,
  LocationState,
  OutletContextType,
} from './(basic-info)/basic-info-constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@apollo/client'
import { CREATE_INVITATION } from '../queries'

export default function GeneralClientsView() {
  const { selectedClient, updateUser, invitationData } = useOutletContext<OutletContextType>()

  const location = useLocation()
  const state = location.state as LocationState

  const [createInvitation] = useMutation(CREATE_INVITATION)

  const methods = useForm<BasicInfoValues>({
    defaultValues: {
      id: selectedClient?.id,
      firstName: selectedClient?.firstName,
      lastName: selectedClient?.lastName,
      email: '',
      isRemoteAccessEnabled: !!selectedClient?.email || !!selectedClient?.isInvitationMode,
      isInvitationMode: selectedClient?.isInvitationMode,
      viewState: 'PREVIEW',
      uuid: selectedClient?.uuid,
    },
    resolver: zodResolver(basicInfoSchema),
  })
  const { reset } = methods

  useEffect(() => {
    onReset()
  }, [selectedClient, state?.editMode, methods])

  // 1. see if we have email in invitation data
  // 2. prefill client fields
  const onReset = () => {
    // if we have preset some states
    const clientObject = {}
    const invitationClient = invitationData?.find(
      ({ partialUserId }) => partialUserId === selectedClient?.id
    )

    // using dot notation..
    if (selectedClient) {
      clientObject['id'] = selectedClient?.id
      clientObject['firstName'] = selectedClient?.firstName
      clientObject['lastName'] = selectedClient?.lastName
      clientObject['email'] = selectedClient?.email
      clientObject['isRemoteAccessEnabled'] =
        state?.isRemoteAccessEnabled ||
        !!selectedClient?.isInvitationMode ||
        !!selectedClient?.email
      clientObject['isInvitationMode'] = selectedClient?.isInvitationMode
      clientObject['viewState'] = state?.editMode ? 'EDIT' : 'PREVIEW'
      clientObject['uuid'] = selectedClient?.uuid
    }
    // for email, the presidence is: client email > invitation email > ''
    reset({
      ...clientObject,
      email: selectedClient?.email || invitationClient?.toEmail || '',
    })
  }

  /**
   * How do we know if a client has an account?
   */
  const onSubmit: SubmitHandler<FieldValues | BasicInfoValues> = async (data) => {
    // if we have email..
    try {
      if (data.email && !methods.formState.defaultValues?.email) {
        await createInvitation({
          variables: {
            invitation: {
              partialUserId: data.id,
              toEmail: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              roles: 'client',
            },
          },
        })
      } else {
        await updateUser({
          variables: {
            user: {
              id: selectedClient.id,
              firstName: data.firstName,
              lastName: data.lastName,
            },
          },
        })
      }

      methods.setValue('viewState', 'PREVIEW')
    } catch (error) {
      const errorMessage = (error as Error)?.message || 'An unknown error occurred'

      // @ts-ignore
      methods.setError('root', { message: errorMessage, type: 'manual' })
    }
  }

  const viewState = methods.watch('viewState')

  return (
    <Grid
      container
      direction={{ lg: 'row', md: 'column-reverse', sm: 'column-reverse', xs: 'column-reverse' }}
    >
      <Grid item sm={12} md={12} lg={9} xl={6}>
        <FormProvider {...methods}>
          {viewState === 'PREVIEW' && <PreviewBasicInfo />}
          {viewState === 'EDIT' && <EditBasicInfo onSubmit={onSubmit} />}
        </FormProvider>
      </Grid>
    </Grid>
  )
}
