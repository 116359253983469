/**
 * https://docs.sentry.io/platforms/javascript/guides/react/
 * we don't need to import sentry browser because sentry react is a wrapper around browser..
 */
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { CaptureConsole } from '@sentry/integrations'
import TagManager from 'react-gtm-module'

import './styles/index.scss'
import './styles/spacing.scss'

import App from './App'
import * as serviceWorker from './serviceWorker'

//https://docs.sentry.io/clients/javascript/tips/
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  ignoreErrors: [
    // random plugins/extensions
    'top.GLOBALS',
    'NetworkError when attempting to fetch resource',
    'Illegal invocation',
    'User not found',
    'Your card has insufficient funds',
    'You already have a client without login with first',
    'Software caused connection abort',
    'Your card was declined',
    'Invitation for this user not found or has been accepted already',
    'Incorrect username or password',
    'Not Authorized: Only provider_all_clients, provider can create Session with type answers',
    'Client License not accessible',
    'Error: email must be unique',
    'NotAuthorizedException: Password attempts exceeded',
    'Validation error: user must have client role',
    'NotAuthorizedException: User is disabled',
    'Your card number is incorrect',
    'Current password is incorrect',
    'Invariant Violation: Invariant Violation: 18',
    'ResizeObserver loop limit exceeded',
    'Could not sign in to Unyte Academy',
    'Your card could not be authorized using the postal code provided',
    /^.*?\bevaluating\b.*?\bpoints\b.*?$/m, // matches any combination of `..evaluating..points..`,
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  tracesSampleRate: process.env.REACT_APP_STAGE === 'prod' ? 0.2 : 1.0,
  normalizeDepth: 3,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.REACT_APP_STAGE === 'prod' ? 0.1 : 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new CaptureConsole({ levels: ['error'] }),
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  beforeSend(event, hint) {
    const error = hint.originalException
    if (error?.message && /ApolloError/.test(error.message)) {
      if (window.location.href.includes('sign-in')) {
        return null // Don't send the event to Sentry
      }
    }
    return event // Send all other events as usual
  },
})

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID })

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// replace removeNode due to google translate bug
// https://stackoverflow.com/questions/21926083/failed-to-execute-removechild-on-node
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild
  Node.prototype.removeChild = function(child) {
    if (child.parentNode !== this) {
      // silently return
      return child
    }
    return originalRemoveChild.apply(this, arguments)
  }

  const originalInsertBefore = Node.prototype.insertBefore
  Node.prototype.insertBefore = function(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      // silently return
      return newNode
    }
    return originalInsertBefore.apply(this, arguments)
  }
}

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    ReactDOM.render(<NextApp />, document.getElementById('root'))
  })
}
