import { z } from 'zod'

type StatusEntry = {
  label: string
  color: string
}

export interface PreviewBasicInfoProps {
  fullName: string
  email: string
  statuses: StatusEntry[]
}

export const basicInfoSchema = z
  .object({
    id: z.number(),
    uuid: z.number().optional(),
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z
      .string()
      .optional()
      .refine(
        (val) => val === undefined || val.trim() === '' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val),
        {
          message: 'Invalid email address',
        }
      ),
    isRemoteAccessEnabled: z.boolean(),
    isInvitationMode: z.boolean(),
    viewState: z.enum(['PREVIEW', 'EDIT']),
  })
  .refine(
    (data) => {
      // If isRemoteAccessEnabled is true, email must be non-empty
      return !data.isRemoteAccessEnabled || (data.email && data.email.trim() !== '')
    },
    {
      message: 'Email is required to enable remote access',
      path: ['email'], // This specifies which field the error message should be associated with
    }
  )
  .refine(
    (data) => {
      // If email is provided, isRemoteAccessEnabled must be true
      return !(data.email && data.email.trim() !== '') || data.isRemoteAccessEnabled
    },
    {
      message: 'Please enable remote access to send invitation.',
      path: ['isRemoteAccessEnabled'], // Specify which field the error message should be associated with
    }
  )
export type BasicInfoValues = z.infer<typeof basicInfoSchema>

export const demographicInfoSchema = z.object({
  id: z.number(),
  age: z.union([
    z
      .number()
      .min(1, { message: 'Age must be greater than 0' })
      .max(100, { message: 'Age must be less than or equal to 100' }),
    z.undefined(),
  ]),
  gender: z.string(),
  country: z.string(),
  viewState: z.enum(['PREVIEW', 'EDIT']),
})
export type DemographicInfoValues = z.infer<typeof demographicInfoSchema>

const preferenceValueSchema = z.object({
  skipAssessmentDemographicsRequest: z.boolean().optional(),
})
const clientSchema = z.object({
  id: z.number(),
  uuid: z.number().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
  isRemoteAccessEnabled: z.boolean().optional(),
  isInvitationMode: z.boolean().optional(),
  birthYear: z
    .string()
    .nullable()
    .optional(),
  gender: z
    .string()
    .nullable()
    .optional(),
  country: z
    .string()
    .nullable()
    .optional(),
  hasDataUse: z.boolean(),
  age: z.union([z.string(), z.number()]), // Updated to handle string and number
  productPreferences: z.record(z.number(), preferenceValueSchema), // Updated to handle number keys with specific value schema
})

const updateUserVariablesSchema = z.object({
  variables: z.object({
    user: z.object({
      id: z.number(),
      firstName: z.string(),
      lastName: z.string(),
    }),
  }),
})

const invitationSchema = z.object({
  createdAt: z.string(),
  acceptedAt: z.string().nullable(),
  expiresAt: z.string(),
  id: z.string(),
  remindedAt: z.string().nullable(),
  remindedTimes: z.number(),
  sentAt: z.string().nullable(),
  toEmail: z.string().email(),
  updatedAt: z.string(),
  userId: z.string(),
  partialUserId: z.number().nullable(),
  firstName: z.string(),
  lastName: z.string(),
  user: z.object({
    id: z.string(),
    fullName: z.string(),
    email: z.string().email(),
  }),
  mailgunMessage: z
    .object({
      mailgunStatus: z.string(),
      updatedAt: z.string(),
      metadata: z.any(),
    })
    .nullable(),
})

const outletContextSchema = z.object({
  selectedClient: clientSchema,
  updateUser: z
    .function()
    .args(updateUserVariablesSchema)
    .returns(z.promise(z.void())),
  invitationData: z.array(invitationSchema).optional(),
})

export type OutletContextType = z.infer<typeof outletContextSchema>

export interface LocationState {
  editMode?: boolean // Adjust the type as needed
  isRemoteAccessEnabled?: boolean
}

const ClientStatusKeys = {
  EnabledAndCreated: 'Remote Access Enabled & Account Created',
  EnabledAndPending: 'Remote Access Enabled & Pending Invitation',
  InPersonOnly: 'In Person Client Only',
}

const BASIC_INFO_COLOURS = {
  lightGreen: '#ACD8C4', // invited
  green: '#65A59B', // in person only
  darkGreen: '#005252', // remote access
}

const ClientStatuses = {
  [ClientStatusKeys.EnabledAndCreated]: [
    {
      label: 'Remote Access',
      color: BASIC_INFO_COLOURS.darkGreen,
    },
  ],
  [ClientStatusKeys.EnabledAndPending]: [
    {
      label: 'Invited to MyUnyte',
      color: BASIC_INFO_COLOURS.lightGreen,
    },
  ],
  [ClientStatusKeys.InPersonOnly]: [
    {
      label: 'In-Person Only',
      color: BASIC_INFO_COLOURS.green,
    },
  ],
}

// get Client status
export const getClientStatus = (email: string, isInvitationMode: boolean): StatusEntry[] => {
  if (isInvitationMode) {
    return ClientStatuses[ClientStatusKeys.EnabledAndPending]
  } else if (!email) {
    return ClientStatuses[ClientStatusKeys.InPersonOnly]
  } else if (email && !isInvitationMode) {
    return ClientStatuses[ClientStatusKeys.EnabledAndCreated]
  }

  return []
}

export const getAgeFromBirthYear = (birthYear: string | null | undefined): number => {
  // Convert birthYear to a number
  if (!birthYear) {
    return 0
  }
  const year = parseInt(birthYear, 10)

  // Check if birthYear is a valid number
  if (isNaN(year)) {
    throw new Error('Invalid birth year')
  }

  // Get the current year
  const currentYear = new Date().getFullYear()

  // Calculate the age
  const age = currentYear - year

  // Ensure age is non-negative
  return Math.max(age, 0)
}

export const getBirthYearFromAge = (age: number | string): number | string => {
  const currentYear = new Date().getFullYear()
  if (typeof age === 'string') {
    if (age === '') {
      return age
    }
    const numericAge = Number(age)
    if (isNaN(numericAge) || numericAge <= 0 || numericAge > 100) {
      throw new Error('Invalid age')
    }
    return currentYear - numericAge
  }
  if (age <= 0 || age > 100) {
    throw new Error('Invalid age')
  }
  return currentYear - age
}

export const DEMOGRAPHIC_TOOLTIP_MESSAGE =
  "Your client's information is fully protected. We follow strict privacy standards and never share client details. For more information, please review our privacy policy."
