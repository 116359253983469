import React, { useState } from 'react'
import {
  Grid,
  Button,
  SvgIcon,
  Card,
  CardMedia,
  CardContent,
  Typography,
  useMediaQuery,
  Divider,
  Collapse,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { get } from 'lodash'
import useCart from '../utils/useCart'
import { useSelector } from 'react-redux'

const useStyle = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: 225,
  },
  media: {
    height: 210,
    width: 210,
  },
  cardHeader: {
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  button: {
    height: '1.5rem',
  },
  overlay: {
    display: 'flex',
    cursor: 'pointer',
  },
  minWidth: {
    minWidth: 0,
  },
  divider: {
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    border: 'none',
    margin: 0,
    width: '33%',
  },
  block: {
    display: 'block',
  },
  center: {
    display: 'grid',
    placeItems: 'center',
    padding: '0.5rem',
  },
})

export default function Product({ product }) {
  // get values from localStorage and parse it
  const { selectedAddOns = {} } = useSelector((state) => state.newPurchase.data)
  const quantity = selectedAddOns[product.SKU] || 0

  const imgSrc = get(product, 'Attachments[0]', '')
  const { SKU, Name, PriceTier1, Description } = product
  const { handleEditProducts } = useCart()
  const [expanded, setExpanded] = useState(false)

  const matches = useMediaQuery('(min-width:513px)') // ipad width is 768
  const classes = useStyle({ quantity, expanded })

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const onAdd = () => {
    handleEditProducts(SKU, 'add')
  }
  const onSubtract = () => {
    if (quantity > 0) {
      handleEditProducts(SKU, 'subtract')
    }
  }

  return (
    <Grid item xs={12} className="py-3">
      <Card className={classes.root} elevation={0} key={SKU}>
        {matches && (
          <>
            <Grid item container xs={5} alignItems="center" justifyContent="center">
              <CardMedia className={classes.media} image={imgSrc} />
            </Grid>
            <Grid item xs={7}>
              <CardContent className="py-1">
                <Grid container direction="column" justifyContent="space-between">
                  <Typography variant="h5" gutterBottom>
                    {Name}
                  </Typography>
                  <Divider />

                  <Collapse in={expanded} collapsedSize={88}>
                    <Typography variant="body2" className="my-2">
                      {Description}
                    </Typography>
                  </Collapse>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    className="h-10"
                  >
                    {Description.length > 200 && (
                      <>
                        <Grid item className={classes.divider} />
                        <Grid item>
                          <Button onClick={handleExpandClick} aria-label="show more">
                            <Typography variant="button">{expanded ? 'Less' : 'More'}</Typography>
                          </Button>
                        </Grid>
                        <Grid item className={classes.divider} />
                      </>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Typography variant="h5" className="p-2">
                      ${PriceTier1}
                    </Typography>
                    <Grid item>
                      <Grid container direction="row">
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={onSubtract}
                          className={classes.minWidth}
                          data-test="product-remove-button"
                        >
                          <SvgIcon component={RemoveIcon} className={classes.button} />
                        </Button>
                        <Typography
                          data-test="product-count"
                          variant="body1"
                          className="text-center m-auto px-5"
                        >
                          {quantity}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={onAdd}
                          className={classes.minWidth}
                          data-test="product-add-button"
                        >
                          <SvgIcon component={AddIcon} className={classes.button} />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </>
        )}
        {!matches && (
          <div className={classes.block}>
            <div className={classes.center}>
              <CardMedia className={classes.media} image={imgSrc} />
            </div>
            <CardContent className="py-1">
              <Grid container direction="column" justifyContent="space-between">
                <Typography variant="h5" gutterBottom>
                  {Name}
                </Typography>
                <Divider />

                <Collapse in={expanded} collapsedSize={88}>
                  <Typography variant="body2" className="my-2">
                    {Description}
                  </Typography>
                </Collapse>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  className="h-10"
                >
                  {Description.length > 200 && (
                    <>
                      <Grid item className={classes.divider} />
                      <Grid item>
                        <Button onClick={handleExpandClick} aria-label="show more">
                          <Typography variant="button">{expanded ? 'Less' : 'More'}</Typography>
                        </Button>
                      </Grid>
                      <Grid item className={classes.divider} />
                    </>
                  )}
                </Grid>

                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Typography variant="h5" className="p-2">
                    ${PriceTier1}
                  </Typography>
                  <Grid item>
                    <Grid container direction="row">
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={onSubtract}
                        className={classes.minWidth}
                      >
                        <SvgIcon component={RemoveIcon} className={classes.button} />
                      </Button>
                      <Typography variant="body1" className="text-center m-auto px-5">
                        {quantity}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={onAdd}
                        className={classes.minWidth}
                      >
                        <SvgIcon component={AddIcon} className={classes.button} />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        )}
      </Card>
    </Grid>
  )
}
