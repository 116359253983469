import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  TableRow,
  TableBody,
  Stack,
  Switch,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'

/**
 * Shows no available licenses when:
 * 1. we don't have anymore seats
 * 2. we haven't previously selected hasSspSlots
 */
export const InPersonTable = () => {
  const { control, formState } = useFormContext()
  const { hasSspProducts, hasRrpProducts, hasFocusProducts } = useSelector((state) => get(state, 'ff', {}))
  const { availableSspSlots, availableRrpSlots, availableFocusSlots } = useSelector((state) =>
    get(state, 'clients', {})
  )

  // get default values
  const fullName = formState.defaultValues?.fullName
  const hasSspSlots = formState.defaultValues?.hasSspSlots
  const hasFocusSlots = formState.defaultValues?.hasFocusSlots
  const hasRrpSlots = formState.defaultValues?.hasRrpSlots

  const sspSlotStatus = formState.defaultValues?.sspSlotStatus
  const rrpSlotStatus = formState.defaultValues?.rrpSlotStatus
  const focusSlotStatus = formState.defaultValues?.focusSlotStatus

  const getStatusMessage = ({ hasSspSlots, hasFocusSlots, hasRrpSlots, fullName }) => {
    const enabledPrograms: string[] = []
    if (hasSspSlots) enabledPrograms.push('SSP')
    if (hasFocusSlots) enabledPrograms.push('ILS')
    if (hasRrpSlots) enabledPrograms.push('RRP')

    if (enabledPrograms.length === 0) {
      return (
        <>
          In-person delivery for {fullName} is disabled. They will <strong>not</strong> appear in
          your Unyte Health client list.
        </>
      )
    }
    const programList = enabledPrograms.join(', ').replace(/, ([^,]*)$/, ' and $1')
    return `You can deliver ${programList} to ${fullName} in-person using your app.`
  }

  const statusMessage = getStatusMessage({ hasSspSlots, hasFocusSlots, hasRrpSlots, fullName })

  return (
    <Stack spacing={2} pt={1}>
      <Typography variant="body2" pt={3}>
        {statusMessage}
      </Typography>
      <TableContainer component={Paper} variant="outlined" sx={{ my: 2 }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography noWrap variant="body2" px={2} fontWeight={500}>
                  Program
                </Typography>
              </TableCell>
              <TableCell align="center">Enable In-Person Delivery</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hasSspProducts && (
              <TableRow>
                <TableCell>
                  <Stack
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    px={2}
                  >
                    <CircleIcon sx={{ color: '#D7D7D7' }} />
                    <Typography noWrap variant="body2">
                      SSP
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  {!availableSspSlots && !hasSspSlots && 'No available licenses'}
                  {(!!availableSspSlots || hasSspSlots) && (
                    <Controller
                      name="hasSspSlots"
                      control={control}
                      disabled={sspSlotStatus === 'used'}
                      render={({ field }) => <Switch {...field} checked={field.value} />}
                    />
                  )}
                </TableCell>
              </TableRow>
            )}
            {hasRrpProducts && (
              <TableRow>
                <TableCell>
                  <Stack
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    px={2}
                  >
                    <CircleIcon sx={{ color: '#D7D7D7' }} />
                    <Typography noWrap variant="body2">
                      RRP
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  {!availableRrpSlots && !hasRrpSlots && 'No available licenses'}
                  {(!!availableRrpSlots || hasRrpSlots) && (
                    <Controller
                      name="hasRrpSlots"
                      control={control}
                      disabled={rrpSlotStatus === 'used'}
                      render={({ field }) => <Switch {...field} checked={field.value} />}
                    />
                  )}
                </TableCell>
              </TableRow>
            )}
            {hasFocusProducts && (
              <TableRow>
                <TableCell>
                  <Stack
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    px={2}
                  >
                    <CircleIcon sx={{ color: '#D7D7D7' }} />
                    <Typography noWrap variant="body2">
                      ILS
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  {!availableFocusSlots && !hasFocusSlots && 'No available licenses'}
                  {(!!availableFocusSlots || hasFocusSlots) && (
                    <Controller
                      name="hasFocusSlots"
                      control={control}
                      disabled={focusSlotStatus === 'used'}
                      render={({ field }) => <Switch {...field} checked={field.value} />}
                    />
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
