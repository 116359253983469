import React, { useState } from 'react'
import MainLayout from 'components/containers/main/Main'
import {
  Box,
  Container,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from 'components'
import Loading from 'components/Loading'
import { useSelector } from 'react-redux'
import { useQuery, gql } from '@apollo/client'
import EMAILS from 'utils/constants/emails'
import { BILLING_TABS } from './tabs'
import { get } from 'lodash'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'

const GET_SLOTS = gql`
  query getSlots($filter: FilterSlotsInput!) {
    getSlots(filter: $filter) {
      category
      status
      type
    }
  }
`

export default function ClientLicenses() {
  const [loading, setLoading] = useState(true)
  const [slots, setSlots] = useState([])
  const queryVars = {
    filter: {
      statuses: ['available', 'assigned', 'used'],
    },
  }

  useQuery(GET_SLOTS, {
    fetchPolicy: 'cache-and-network',
    variables: queryVars,
    onCompleted: (data) => {
      setSlots(get(data, 'getSlots', []))
      setLoading(false)
    },
  })

  const {
    hasSspProducts,
    hasRrpProducts,
    hasFocusProducts,
    showBillingHistory,
    showNewSSPSubscriptionPlan,
    showNewFocusSubscriptionPlan,
  } = useSelector((state) => state.ff)
  const {
    selectedUser: {
      hasCompletedFocusCertification,
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
    },
  } = useGetUserWithoutRefresh()

  const tabs = BILLING_TABS.filter(({ isEnabled }) =>
    isEnabled({
      showBillingHistory,
      hasSspProducts,
      hasRrpProducts,
      hasFocusProducts,
      showNewSSPSubscriptionPlan,
      showNewFocusSubscriptionPlan,
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
      hasCompletedFocusCertification,
    })
  )

  const availableSSPSlots = slots.filter(
    (slot) => slot.category === 'ssp' && slot.status === 'available'
  )
  const assignedSSPSlots = slots.filter(
    (slot) => slot.category === 'ssp' && slot.status === 'assigned'
  )
  const usedSSPSlots = slots.filter((slot) => slot.category === 'ssp' && slot.status === 'used')

  const availableRRPSlots = slots.filter(
    (slot) => slot.category === 'rrp' && slot.status === 'available'
  )
  const assignedRRPSlots = slots.filter(
    (slot) => slot.category === 'rrp' && slot.status === 'assigned'
  )
  const usedRRPSlots = slots.filter((slot) => slot.category === 'rrp' && slot.status === 'used')

  const availableFocusSlots = slots.filter(
    (slot) => slot.category === 'focus' && slot.status === 'available'
  )

  const assignedFocusSlots = slots.filter(
    (slot) => slot.category === 'focus' && slot.status === 'assigned'
  )
  const usedFocusSlots = slots.filter((slot) => slot.category === 'focus' && slot.status === 'used')

  return (
    <MainLayout title="My Billing" tabs={tabs}>
      <Container className="pt-5">
        <Grid container spacing={2} alignItems="stretch" justifyItems="space-between" mt={1}>
          {loading && <Loading rows={1} width={['450px']} height={['350px']} />}
          {!loading && showNewSSPSubscriptionPlan === true && hasCompletedSspCertification && (
            <ClientLicenseSummary
              category={'SSP'}
              availableSlots={availableSSPSlots.length}
              assignedSlots={assignedSSPSlots.length}
              usedSlots={usedSSPSlots.length}
            />
          )}
          {!loading && showNewFocusSubscriptionPlan === true && hasCompletedFocusCertification && (
            <ClientLicenseSummary
              category={'ILS'}
              availableSlots={availableFocusSlots.length}
              assignedSlots={assignedFocusSlots.length}
              usedSlots={usedFocusSlots.length}
            />
          )}
          {!loading /* && hasCompletedRrpCertification */ && (
            <ClientLicenseSummary
              category={'RRP'}
              availableSlots={availableRRPSlots.length}
              assignedSlots={assignedRRPSlots.length}
              usedSlots={usedRRPSlots.length}
            />
          )}
        </Grid>
        <br />
        <Typography className="pb-5" variant="body1">
          For assistance or questions about client licenses, please contact{' '}
          <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
            {EMAILS.supportEmail}
          </a>
          .
        </Typography>
        <Typography variant="body1">Coming soon - details of your client license usage.</Typography>
      </Container>
    </MainLayout>
  )
}

const ClientLicenseSummary = ({
  category,
  availableSlots = 0,
  assignedSlots = 0,
  usedSlots = 0,
}) => {
  const rows = [
    {
      text: 'Available',
      value: availableSlots,
    },
    {
      text: 'Assigned',
      value: assignedSlots,
    },
    {
      text: 'In Use',
      value: usedSlots,
    },
    {
      text: 'Total',
      value: availableSlots + assignedSlots + usedSlots,
    },
  ]
  return (
    <Card backgroundColor="#f7f7f7" sx={{ width: '30%', marginRight: '20px' }}>
      <CardHeader title={`Your ${category} Client Licenses`} color="#3c7e7d" />
      <Box sx={{ margin: '0 0.5rem 0 0.5rem' }}>
        <CardContent sx={{ padding: '1rem 0.5rem 0rem 0.5rem' }}>
          <Table>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" sx={{ width: '50%', borderBottom: 'none' }}>
                    {row.text}
                  </TableCell>
                  <TableCell component="th" sx={{ width: '50%', borderBottom: 'none' }}>
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Box>
    </Card>
  )
}
