import {
  Card,
  Typography,
  Stack,
  CardHeader,
  CardContent,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  CardActions,
  FormHelperText,
} from '@mui/material'
import React from 'react'
import { Controller, FieldValues, SubmitHandler, useFormContext } from 'react-hook-form'
import { BasicInfoValues } from './basic-info-constants'

interface EditBasicInfoProps {
  onSubmit: SubmitHandler<FieldValues | BasicInfoValues>
}

export const EditBasicInfo: React.FC<EditBasicInfoProps> = ({ onSubmit }) => {
  const { control, handleSubmit, setValue, reset, watch, formState } = useFormContext()

  const handleReset = () => {
    reset()
    setValue('viewState', 'PREVIEW')
  }

  // we are a remote client when you have email, and toggled remote access and accepted invitation
  const isRemoteClient =
    formState?.defaultValues?.email &&
    formState?.defaultValues?.isRemoteAccessEnabled &&
    !formState?.defaultValues?.isInvitationMode

  return (
    <Card variant="outlined" sx={{ p: 2, m: 2 }}>
      <CardHeader title="Basic Info" />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
          <Stack direction="row" spacing={2}>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Client First Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Client Last Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Stack>

          <Stack alignItems="center" direction="row" justifyContent="space-between" pt={2}>
            <Typography sx={{ width: '100%' }}>Enable remote access for this client</Typography>
            <Controller
              name="isRemoteAccessEnabled"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl
                  fullWidth
                  error={!!fieldState.error}
                  // disable this field if user already accepted invitation
                  disabled={formState?.defaultValues?.isInvitationMode}
                >
                  <FormControlLabel
                    control={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography>OFF</Typography>
                        <Switch {...field} checked={!!watch('isRemoteAccessEnabled')} />
                        <Typography>ON</Typography>
                      </Stack>
                    }
                    label=""
                    labelPlacement="start"
                    sx={{ margin: 0 }}
                  />
                  {!!fieldState.error && (
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Stack>
          <Typography variant="body2" textAlign="justify" display="block">
            This allows the client to have controlled access to the MyUnyte platform and app for
            remote program and/or remote assessment delivery.
            {!isRemoteClient && 'You can also do this later.'}
          </Typography>

          {watch('isRemoteAccessEnabled') && (
            <>
              {!isRemoteClient && (
                <Typography variant="body2" textAlign="justify" display="block" pt={1}>
                  Please invite the client to create their own MyUnyte account for remote access:
                </Typography>
              )}
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Client Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    disabled={!!formState.defaultValues?.email}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              {!isRemoteClient && (
                <Typography sx={{ span: { fontWeight: 500 } }} variant="body2">
                  <span>Note:</span> an email invitation will be sent to the client.
                </Typography>
              )}
            </>
          )}
          <CardActions sx={{ marginLeft: '-1.5rem' }}>
            <Stack direction="column" spacing={1}>
              {!!formState.errors.root && (
                <FormHelperText error={!!formState.errors.root} id="root-error">
                  {formState.errors.root.message}
                </FormHelperText>
              )}
              <Stack direction="row" spacing={2}>
                <Button type="submit" color="primary" disabled={!formState.isDirty}>
                  Save
                </Button>
                <Button type="reset" color="primary">
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </CardActions>
        </form>
      </CardContent>
    </Card>
  )
}
