export default {
  invitation: {
    termsOfService: '//integratedlistening.com/about-ils/terms-of-service',
    termsOfUse: '//integratedlistening.com/about-ils/terms-of-use',
    privacyNotice: '//integratedlistening.com/about/privacy-notice',
  },
  download: {
    playStore: {
      href: '//play.google.com/store/apps/details?id=com.unyte.ils&hl=en',
      imgSrc: '/images/dashboard/GoogleLogo.png',
    },
    appStore: {
      href: '//apps.apple.com/us/app/unyte-health/id1514807633',
      imgSrc: '/images/dashboard/AppleLogo.svg',
    },
  },
  billing: {
    changeOrCancel: 'https://support.unyte.com/how-does-an-ssp-or-focus-subscription-work',
  },
  newPurchase: {
    redirectUrl: 'https://integratedlistening.com/',
  },
  professionalCredentials: {
    applicationForm:
      '//docs.google.com/forms/d/e/1FAIpQLSfi0PsB5-3SYVVKk0f_UN7CCV7S-sAsNFpuS-yAvv44AQXXtw/viewform',
  },
  support: {
    homepageUrl: '//support.unyte.com',
    domainName: 'support.unyte.com',
    focusHeadphonesUrl: 'https://support.unyte.com/top-10-questions-about-the-unyte-focus-system-1',
    sspHeadphonesUrl: 'https://support.unyte.com/what-headphones-do-you-recommend-for-ssp',
    youcanbookme: 'https://connect.unyte.com/meetings/unyte/success-mu',
    welcomeCall: 'https://connect.unyte.com/meetings/unyte/welcome',
    postTrainingSales: 'https://connect.unyte.com/meetings/unyte/post-training-sales',
  },
}
