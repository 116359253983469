import React from 'react'
import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import { CardContent } from '@material-ui/core'

export default ({ data }) => (
  // we need to use padding here or grid will be pushed
  <Grid item container xs={12} sm={8} spacing={1} pl={4}>
    {data
      .filter(({ value }) => !!value)
      .map(({ label, value }, index) => {
        return (
          <Grid item xs={12} sm={6} key={`previewUserDetails-${index}`}>
            <Typography variant="subtitle2" fontWeight={400}>
              {label}
            </Typography>
            <Typography variant="body2">{value}</Typography>
          </Grid>
        )
      })}
  </Grid>
)

export const PreviewSlot = ({ data }) => {
  if (!data?.length) {
    return null
  }
  return (
    <Grid item>
      <Card sx={{ backgroundColor: '#addfd7', py: 1, px: 2 }} elevation={0}>
        {data.map(({ label, value }, index) => (
          <div key={`preview${label}${index}`}>
            <Typography variant="subtitle2">{label}</Typography>
            <Typography variant="body2">{value}</Typography>
          </div>
        ))}
      </Card>
    </Grid>
  )
}

export const BoundingInfoBox = ({ list, label }) => {
  return (
    <Box sx={{ display: 'inline-flex', maxWidth: '100%' }}>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: '#F2F2F2 !important',
          borderRadius: '15px !important',
          borderColor: '#E4E4E4',
        }}
        elevation={0}
      >
        <CardContent>
          <Typography variant="subtitle2" pb={2}>
            {label}
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            {list.map(({ label, value }, index) => (
              <Grid item key={index}>
                <Typography variant="subtitle2">{label}</Typography>
                <Typography variant="body2">{value}</Typography>
              </Grid>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}
