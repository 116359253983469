import React from 'react'
import { NavLink } from 'react-router-dom'
import { matchPath, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import ROLES from 'utils/constants/roles'
import { get, isFunction } from 'lodash'
import { menu } from 'utils/constants/routes'

import { List, ListItem, Hidden, Drawer, Link, Badge, Box } from '@mui/material'

import styles from './Navbar.module.scss'
import useGetUser from '../../utils/hooks/useGetUser'
import { setMenuState } from 'store/modules/app'

export const NAVBAR_WIDTH = 150

export default function Navbar() {
  const dispatch = useDispatch()
  const location = useLocation()
  const showMenu = useSelector((state) => get(state, 'app.showMenu', false))
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isClient = currentUserRoles.some((role) => ROLES.CLIENT_ROLES.includes(role))
  const hasAssessmentProducts = useSelector((state) => !!state.ff.hasAssessmentProducts)
  const hasSspProducts = useSelector((state) => !!state.ff.hasSspProducts)
  const hasFocusProducts = useSelector((state) => !!state.ff.hasFocusProducts)
  const professionalCredentials = useSelector((state) =>
    get(state, 'auth.user.professionalCredentials', null)
  )
  const otherProfessionalCredentials = useSelector((state) =>
    get(state, 'auth.user.otherProfessionalCredentials', null)
  )
  const isProvider = currentUserRoles.some((role) => ROLES.PROVIDER_ROLES.includes(role))
  const providerProfileInfo = useSelector((state) =>
    get(state, 'auth.user.providerProfileInfo', {})
  )
  const productPreferences = useSelector((state) => get(state, 'auth.user.productPreferences', {}))
  const organizationSettings = useSelector((state) =>
    get(state, 'auth.user.organization.settings', {})
  )
  const organizationProductsStatus = useSelector((state) => get(state, 'organization.products', {}))
  const tags = useSelector((state) => get(state, 'auth.user.tags', []))
  const showClientConnectionsBadge = useSelector((state) => state?.ff?.showClientConnectionsBadge)

  // ILS ITEMS
  const hasPaidFocusSubscription = useSelector(
    (state) => state.organization.hasPaidFocusSubscription
  )

  const {
    selectedUser: {
      hasSspCertification,
      hasCompletedSspCertification,
      hasFocusCertification,
      hasCompletedFocusCertification,
      hasVoiceProCertification,
      hasCompletedVoiceProCertification,
      sspInTrainingOnly,
      focusInTrainingOnly,
    },
  } = useGetUser()

  const filteredMenu = menu
    .filter(({ roles, isEnabled }) => {
      const fitRoles = currentUserRoles.some((role) => roles.includes(role))
      const enable = isFunction(isEnabled)
        ? isEnabled({
            hasAssessmentProducts,
            hasCompletedSspCertification,
            hasSspCertification,
            hasFocusCertification,
            hasCompletedFocusCertification,
            hasVoiceProCertification,
            hasCompletedVoiceProCertification,
            isClient,
            hasSspProducts,
            hasFocusProducts,
            isProvider,
            professionalCredentials,
            otherProfessionalCredentials,
            hasPaidFocusSubscription,
            tags,
            sspInTrainingOnly,
            focusInTrainingOnly,
            providerProfileInfo,
            productPreferences,
            organizationSettings,
            organizationProductsStatus,
          })
        : true
      return fitRoles && enable
    })
    .map((item) => ({
      ...item,
      showBadge: isFunction(item.showBadge) && item.showBadge({ showClientConnectionsBadge }),
    }))

  const renderNavContent = () => (
    <Box
      sx={{
        width: `${NAVBAR_WIDTH}px`,
        flexShrink: '0',
        backgroundColor: 'rgb(245, 245, 245)',
        height: '100%',
        overflowY: 'auto',
        color: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Link to="/">
          {/* image is 250px wide */}
          <img
            style={{
              width: '100%',
              padding: '1rem',
              paddingBottom: '0rem',
            }}
            src="/images/myunyte.png"
            alt="Logo"
          />
        </Link>
        <List>
          {filteredMenu.map(({ text, url, showBadge }) => (
            <NavLink
              key={text}
              to={url}
              data-test={`nav-link-${text}`}
              className={
                matchPath({ path: url, end: url === '/' }, location.pathname)
                  ? `${styles.active} ${styles.link}`
                  : styles.link
              }
            >
              <ListItem>
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={!showBadge}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {text}
                </Badge>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </div>

      <div className="px-2 pb-4">
        <img
          src="/images/unyte-logo.png"
          alt="Unyte"
          className={styles.footerLogo}
          style={{ width: '50px', margin: 'auto' }}
        />
      </div>
    </Box>
  )

  return (
    <React.Fragment>
      <Hidden mdDown>{renderNavContent()}</Hidden>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={showMenu}
          onClose={() => dispatch(setMenuState(false))}
          onClick={() => dispatch(setMenuState(false))}
        >
          {renderNavContent()}
        </Drawer>
      </Hidden>
    </React.Fragment>
  )
}
