const ACTIVE_SUBSCRIPTION_STATUS = ['paid', 'past_due']
const IGNORED_SUBSCRIPTION_SKUS = [
  'sspAustralia',
  'sspComplimentary',
  'sspCustom',
  'focusCustom',
  'focusAustralia',
  'focusComplimentary',
]

export function canAccessCommunity({
  isProvider,
  hasSspProducts,
  hasFocusProducts,
  organizationSettings,
  organizationProductsStatus,
  providerProfileInfo,
  productPreferences,
}) {
  const hasIgnoredSubscription = IGNORED_SUBSCRIPTION_SKUS.some((sku) =>
    ACTIVE_SUBSCRIPTION_STATUS.includes(organizationProductsStatus[sku]?.status)
  )

  const isSspCertificationCompleted = !!productPreferences?.sspCertification?.completedAt
  const isFocusCertificationCompleted = !!productPreferences?.focusCertification?.completedAt
  const hasSubscriptionAndCertification =
    (hasSspProducts && isSspCertificationCompleted) ||
    (hasFocusProducts && isFocusCertificationCompleted)
  const accessSetManually = Object.keys(providerProfileInfo).includes('canAccessCommunity')

  return (
    !organizationSettings?.isCommunityDisabledForAllUsers &&
    ((isProvider &&
      hasSubscriptionAndCertification &&
      !hasIgnoredSubscription &&
      !accessSetManually) ||
      providerProfileInfo?.canAccessCommunity)
  )
}
