import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Box, Card, CardHeader, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { useForm } from 'hooks'
import ROLES from 'utils/constants/roles'
import { Button } from 'components'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { useNavigate } from 'react-router'
import SelectCountry from 'components/ecommerce/shipping/SelectCountry'
import SelectGender from 'components/form/select-gender'
import { Info } from '@mui/icons-material'
import { get } from 'lodash'
import { CREATE_USER, CREATE_INVITATION } from './constants/constants'
import useGetAssessmentId from 'views/assessments/utils/useGetAssessmentId'
import { DEMOGRAPHIC_TOOLTIP_MESSAGE } from './details/(basic-info)/basic-info-constants'

const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      productPreferences
    }
  }
`

export default function CreateNewClient() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { assessmentProductId } = useGetAssessmentId()

  const [enableRemoteDelivery, setEnableRemoteDelivery] = useState(false)
  const [createUser] = useMutation(CREATE_USER)
  const [updateUser] = useMutation(UPDATE_USER)
  const [createInvitation] = useMutation(CREATE_INVITATION)

  const roles = [...ROLES.CLIENT_ROLES]
  const { form, setFormValue, errors, pending, isValid, onSubmit, reset } = useForm({
    data: {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      gender: '',
      birthYear: '',
      consent: false,
      roles,
      consentCounter: 0,
    },
    validation: {
      firstName: {
        required: { msg: 'First name is required.' },
        len: {
          args: [null, 100],
          msg: 'First name must be no longer than 100 characters',
        },
      },
      lastName: {
        required: { msg: 'Last name is required.' },
        len: {
          args: [null, 100],
          msg: 'Last name must be no longer than 100 characters',
        },
      },
      email: {
        len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
        email: { msg: 'Please enter a valid email address.' },
      },
      birthYear: {
        birthYear: { msg: 'Please enter a valid age.' },
      },
    },
    async onSubmit(event) {
      event.preventDefault()

      try {
        const newUserVariable = {
          firstName: form.firstName,
          lastName: form.lastName,
          roles: [ROLES.CLIENT],
          isInvitationMode: enableRemoteDelivery && form.email.trim().length > 0,
        }
        if (form.country) newUserVariable.country = form.country
        if (form.gender) newUserVariable.gender = form.gender
        if (form.birthYear) newUserVariable.birthYear = form.birthYear
        if (form.email) newUserVariable.email = form.email // adding email for duplication check in backend

        const result = await createUser({
          variables: {
            user: newUserVariable,
          },
        })
        const clientId = get(result, 'data.createUser.id')

        if (enableRemoteDelivery && form.email) {
          const newInviteVariable = {
            firstName: form.firstName,
            lastName: form.lastName,
            toEmail: form.email,
            partialUserId: clientId,
            roles: [ROLES.CLIENT],
          }

          await createInvitation({
            variables: {
              invitation: newInviteVariable,
            },
          })
        }

        if (form.consentCounter) {
          await updateUser({
            variables: {
              user: {
                id: clientId,
                productPreferences: {
                  [assessmentProductId]: { skipAssessmentDemographicsRequest: form.consent },
                },
              },
            },
          })
        }

        enqueueSnackbar('Client created successfully', {
          variant: 'success',
          action: CloseSnackbarAction,
        })

        reset()
        navigate(`/clients/${clientId}`)
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
          action: CloseSnackbarAction,
        })
        return // Prevent further actions from executing
      }
    },
  })

  const handleCheckbox = (event) => {
    setEnableRemoteDelivery(event.target.checked)
    if (!event.target.checked) {
      setFormValue('email', { path: '' })('')
    }
  }

  const handleAgeChange = (e) => {
    const currentYear = new Date().getFullYear()
    const age = e.target.value
    const birthYear = age ? currentYear - age : ''
    setFormValue('birthYear', { path: '' })(birthYear)
  }

  const onBack = () => {
    navigate('/clients')
  }

  const isSubmitButtonDisabled = () => {
    if (pending || !isValid) return true
    if (enableRemoteDelivery && (!form.email || form.email.trim() === '')) return true
    return false
  }

  return (
    <Card p={3} sx={{ border: 'none', outline: 'none', boxShadow: 0 }}>
      <Box component="form" onSubmit={onSubmit} width={{ lg: '50%', md: '65%', sm: '80%' }} ml={2}>
        <CardHeader title="Basic Info" />
        <Stack direction="row" alignItems="flex-start" justifyContent="center" ml={2} spacing={2}>
          <TextField
            value={form.firstName}
            fullWidth
            onChange={setFormValue('firstName')}
            label="Client First Name"
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            value={form.lastName}
            fullWidth
            onChange={setFormValue('lastName')}
            label="Client Last Name"
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </Stack>

        <Stack direction="row" alignItems="center" pl={2} pt={4} spacing={2}>
          <Typography>Enable remote access for this client</Typography>
          <Stack direction="row" alignItems="center" pl={2}>
            <Typography>OFF</Typography>
            <Switch onChange={handleCheckbox} value={enableRemoteDelivery} />
            <Typography>ON</Typography>
          </Stack>
        </Stack>
        <Typography pl={2} variant="body2" textAlign="justify" display="block">
          This allows the client to have controlled access to the MyUnyte platform and app for
          remote program and/or remote assessment delivery. You can also do this later.
        </Typography>

        {enableRemoteDelivery && (
          <>
            <Typography pl={2} py={1} variant="body2">
              Please invite the client to create their own MyUnyte account for remote access:
            </Typography>
            <TextField
              value={form.email}
              fullWidth
              onChange={setFormValue('email')}
              label="Client Email"
              error={!!errors.email}
              helperText={errors.email}
              sx={{ marginLeft: 2, alignSelf: 'center' }}
            />
            <Typography pl={2} sx={{ span: { fontWeight: 500 } }} variant="body2">
              <span>Note:</span> an email invitation will be sent to the client.
            </Typography>
          </>
        )}

        <CardHeader
          title={
            <Stack direction="row">
              <Typography variant="h5">Demographic Info (Optional)</Typography>
              <Tooltip
                placement="top-start"
                title={
                  <Typography variant="body2" color="white">
                    {DEMOGRAPHIC_TOOLTIP_MESSAGE}
                  </Typography>
                }
              >
                <Info sx={{ ml: 1, height: '100%', width: 30, color: '#058181' }} />
              </Tooltip>
            </Stack>
          }
        />
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          ml={2}
          spacing={2}
          pb={3}
        >
          <TextField
            className="w-full"
            label={`Age in ${new Date().getFullYear()}`}
            type="number"
            inputProps={{ min: 0 }}
            onChange={handleAgeChange}
            helperText={errors.birthYear && 'Age must be between 0-100'}
            error={!!errors.birthYear}
          />

          <SelectGender value={form.gender} onChange={setFormValue('gender')} label="Gender" />

          <SelectCountry
            showFullName
            value={form.country}
            onChange={setFormValue('country')}
            formClassName="w-full"
            label="Country"
          />
        </Stack>

        <Stack flexDirection="row" m={2}>
          <Button
            disabled={isSubmitButtonDisabled()}
            type="submit"
            color="primary"
            loading={pending}
            variant="contained"
            sx={{ marginRight: 2 }}
          >
            Create Client
          </Button>
          <Button color="primary" disabled={pending} onClick={onBack}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Card>
  )
}
