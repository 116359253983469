import React from 'react'
import { Avatar, Badge, Tooltip, Stack, Typography, Chip, Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { getClientStatus } from '../clients/details/(basic-info)/basic-info-constants'
import get from 'lodash/get'

const BadgeComponent = ({ children, badgeColor, badgeText, badgeInvisible }) => (
  <Badge
    color={badgeColor}
    badgeContent={badgeText}
    data-test="client-active-badge"
    invisible={badgeInvisible}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    sx={{ '& .MuiBadge-badge': { fontSize: 10, color: 'white', backgroundColor: badgeColor } }}
  >
    {children}
  </Badge>
)

const Wrapper = ({ children, isSuspended, activeClientsFilter, isInvitationMode, isArchived }) => {
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)

  // Set all the flags into a conditions array
  const conditions = [
    { flag: isSuspended, props: { color: 'error', text: 'Suspended', invisible: false } },
    { flag: isArchived, props: { color: '#949494', text: 'Archived', invisible: false } },
    { flag: isInvitationMode, props: { color: '#3F84AC', text: 'Invited', invisible: false } },
  ]
  // define the default values
  const defaultProps = { color: 'primary', text: 'Active', invisible: !activeClientsFilter }

  // badgeProps finds the first condition in the conditions array where the flag is true and returns its props.
  // If no such condition is found, it falls back to defaultProps.
  const { color: badgeColor, text: badgeText, invisible: badgeInvisible } =
    conditions.find(({ flag }) => flag)?.props || defaultProps

  const renderTooltipContent =
    activeClientsFilter && !isSuspended ? (
      <>
        An <span className="font-semibold">active client</span> is a client who has listened to 30
        minutes or more of any SSP music within your monthly billing cycle.
      </>
    ) : (
      ''
    )

  return !showNewSubscriptionPlan ? (
    <Tooltip title={renderTooltipContent}>
      <BadgeComponent badgeColor={badgeColor} badgeText={badgeText} badgeInvisible={badgeInvisible}>
        {children}
      </BadgeComponent>
    </Tooltip>
  ) : (
    <BadgeComponent badgeColor={badgeColor} badgeText={badgeText} badgeInvisible={badgeInvisible}>
      {children}
    </BadgeComponent>
  )
}

export default ({
  firstName,
  lastName,
  isSuspended,
  email,
  activeClientsFilter,
  isInvitationMode,
  isArchived,
  isSelected,
}) => {
  const { showNewClientsPage } = useSelector((state) => get(state, 'ff', {}))
  const statuses = getClientStatus(email, isInvitationMode)

  return (
    <Stack direction="row" sx={{ ml: 2 }}>
      <Wrapper
        isSuspended={isSuspended}
        activeClientsFilter={activeClientsFilter}
        isInvitationMode={isInvitationMode}
        isArchived={isArchived}
      >
        <Avatar
          firstName={firstName}
          lastName={lastName}
          suspended={isSuspended}
          isInvitationMode={isInvitationMode}
          isArchived={isArchived}
        />
      </Wrapper>
      <Stack direction="column" mx={1}>
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          {firstName} {lastName}
        </Typography>
        <Typography variant="body2">{email}</Typography>
        <Box pt={1}>
          {showNewClientsPage &&
            isSelected &&
            statuses.map((status) => (
              <Chip
                key={`${status.label}-chip`}
                label={status.label}
                color="primary"
                sx={{
                  backgroundColor: status.color,
                }}
              />
            ))}
        </Box>
      </Stack>
    </Stack>
  )
}
