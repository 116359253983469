import {
  Container,
  Grid,
  CardContent,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CardMedia,
  Typography,
  Stack,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router'
import { get } from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import useGetUser from 'utils/hooks/useGetUser'
import ROLES from '../../utils/constants/roles'
import { includesSome } from '../../utils/includes'
import useGTM from 'utils/hooks/useGTM'
import { rrpCardConfigs as cards } from './components/StoreFrontCardConfigs'
import BookACallCard from './components/BookACallCard'
import Card from './components/PurchaseCardComponent'
import CardHeader from './components/PurchaseCardHeader'
import PurchaseCard from './components/PurchaseCard'
import ContactAdministratorMessage from './components/ContactAdministratorMessage'
import { extendData } from 'store/modules/new-purchase'

const MARGIN_LEFT = '0.5rem'

export default function RRPStoreFront() {
  const { setMainLayoutTitle } = useOutletContext()
  const { selectedUser } = useGetUser()
  const { hasRrpCertification, roles } = selectedUser

  const isProvider = includesSome(roles, [...ROLES.PROVIDER_ROLES])
  const isBilling = roles.includes(ROLES.BILLING)
  // const isProviderWithBilling = isProvider && isBilling

  const {
    hasOrgRrpCertificationCompleted,
    hasPaidRrpSubscription,
    hasPastDueRrpSubscription,
    hasPendingRrpSubscription,
    hasPaidFocusSubscription,
    hasPastDueFocusSubscription,
    hasPendingFocusSubscription,
    hasAllCanceledRrpSubscription,
  } = useSelector((state) => get(state, 'organization', {}))

  const hasActiveRrpSubscription =
    hasPastDueRrpSubscription || hasPaidRrpSubscription || hasPendingRrpSubscription

  const hasActiveFocusSubscription =
    hasPaidFocusSubscription || hasPastDueFocusSubscription || hasPendingFocusSubscription

  const showTrainingPurchaseOption = false /* isProviderWithBilling && !hasRrpCertification */
  const showSubscriptionPurchaseOptions = isBilling && !hasActiveRrpSubscription

  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)

  // set the title on load
  const { pageView } = useGTM()
  useEffect(() => {
    const title = 'Purchase RRP'
    setMainLayoutTitle(title)

    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'Internal Purchase - Select items',
    })
    // eslint-disable-next-line
  })

  const subscriptionItems = [
    'MyUnyte online platform for managing client delivery and accessing numerous practical and clinical tools including assessments and resources',
    'The Unyte Health app for in-clinic and remote RRP delivery',
    'Live and online support from our Client Success team plus provider-only events and monthly Q&A’s',
    '25 Client Licenses included with annual plan; 6 included with 3 month plan*',
  ].filter(Boolean) // Filter out falsey values

  // set change url to here
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      extendData({
        redirectUrl: '/store',
      })
    )
    // eslint-disable-next-line
  }, [])

  return (
    <Container maxWidth="md" sx={{ margin: '0 0 50px 0' }}>
      {/* Header */}
      <Grid container spacing={1} alignItems="stretch" justifyItems="space-between" mt={1}>
        <Grid sm={6}>
          <CardMedia
            image="/images/store/rrp-store-front-header.jpg"
            sx={{
              height: '300px',
              maxWidth: '330px',
              margin: '0 0 0 15%',
            }}
          />
        </Grid>
        <Grid sm={6}>
          <Typography color="#3c7e7d" sx={{ fontSize: 30 }}>
            Rest and Restore Protocol: Certification and Subscriptions
          </Typography>
          <br />
          <Typography sx={{ fontWeight: 500 }}>
            Start guiding your clients’ nervous systems to become more aware, regulated and
            resilient so they can more effectively respond to life’s challenges.
          </Typography>
          <br />
          <br />
          <Typography>
            Our subscription options make it easy to integrate Polyvagal Theory into your practice!
          </Typography>
        </Grid>
      </Grid>

      {/* plans */}
      {isBilling && (
        <Grid container spacing={1} alignItems="stretch" justifyItems="space-between" mt={1}>
          {cards
            .filter(({ isActive }) =>
              isActive({
                hasRrpCertification,
                hasOrgRrpCertificationCompleted,
                hasActiveRrpSubscription,
                hasAllCanceledRrpSubscription,
              })
            )
            .map(
              ({
                title,
                costPerMonth,
                description,
                trainingMessage,
                subDescription,
                link,
                skus,
                testName,
              }) => (
                <Grid item xs={12} sm={4} data-test={testName}>
                  <PurchaseCard
                    title={title}
                    costPerMonth={costPerMonth}
                    description={description}
                    trainingMessage={trainingMessage}
                    subDescription={subDescription({ hasActiveFocusSubscription })}
                    link={link}
                    skus={skus}
                    marginLeft={MARGIN_LEFT}
                    data-test={testName}
                  />
                </Grid>
              )
            )}
        </Grid>
      )}

      {/* RRP Subscription items */}
      <Grid container spacing={1} alignItems="stretch" sx={{ marginTop: '0rem' }}>
        {showSubscriptionPurchaseOptions && (
          <Grid item xs={12} sm={8} data-test="rrp-subscription-details-card">
            <Card backgroundColor="#f7f7f7">
              <CardHeader
                title="Your RRP Subscription Includes:"
                color="#3c7e7d"
                sx={{ paddingBottom: 0 }}
                data-test="rrp-subscription-details-card"
              />
              <Box sx={{ margin: '0 0.5rem 0 0.5rem' }}>
                <CardContent sx={{ padding: '0 0.5rem 0rem 0.5rem' }}>
                  <List>
                    {subscriptionItems.map((item) => (
                      <ListItem dense disableGutters>
                        <ListItemIcon sx={{ minWidth: '36px' }}>
                          <AddIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={item}
                          primaryTypographyProps={{ variant: 'body2' }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
                <CardContent
                  sx={{ padding: '0 0.5rem 0.5rem 0.5rem', margin: '0 0.5rem 0 0.5rem' }}
                >
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      * Each Client License is accessible for 12 months from the start of listening.
                      Add additional Client Licenses anytime for $25.
                    </Typography>
                    <Typography variant="caption">
                      ** All subscription plans automatically renew.
                    </Typography>
                    {!showNewSubscriptionPlan && (
                      <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                        Please{' '}
                        <a
                          href="https://connect.unyte.com/meetings/unyte/success-mu"
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none' }}
                        >
                          Book a call
                        </a>{' '}
                        with our Client Success team or contact{' '}
                        <a
                          href="mailto:success@unyte.com"
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none' }}
                        >
                          success@unyte.com
                        </a>{' '}
                        to discuss options and discounts.
                      </Typography>
                    )}
                  </Stack>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        )}

        <Grid
          item
          sx={{ display: 'flex', flexDirection: 'column' }}
          xs={12}
          sm={4}
          direction="column"
        >
          {showTrainingPurchaseOption && (
            /* Purchase RRP training */
            <Grid
              sx={{ margin: '0 0 5px 0' }}
              item
              md={4}
              xs={12}
              sm={4}
              data-test="rrp-certification-card"
            >
              <PurchaseCard
                title={'Foundational RRP Training'}
                oneTimeCost={349}
                description={
                  <>
                    <Typography>
                      Get RRP online training! Purchase your subscription later.
                    </Typography>
                    <br />
                    <Typography>
                      Please note: an active RRP subscription is required to deliver the RRP program
                    </Typography>
                  </>
                }
                link={'/purchase/confirm'}
                skus={['rrpCertification']}
                marginLeft={MARGIN_LEFT}
                data-test="rrp-certification-card"
              />
            </Grid>
          )}
          {/* Book a call */}
          {showSubscriptionPurchaseOptions && <BookACallCard sourceParam="mu_rrp_sub" />}
        </Grid>
      </Grid>

      {isProvider && !isBilling && <ContactAdministratorMessage />}
    </Container>
  )
}
